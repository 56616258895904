#container {
  justify-content: center;
  width: 200px;
  height: 200px;
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}
