body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway" !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "TeslaFont";
  src: local("TeslaFont"),
    url("./assets/fonts/TeslaFont.ttf") format("truetype");
}

@font-face {
  font-family: "BonaNovaSCFont";
  src: local("BonaNovaSCFont"),
    url("./assets/fonts/BonaNovaSC.ttf") format("truetype");
}

@font-face {
  font-family: "NeonFont";
  src: local("NeonFont"), url("./assets/fonts/NeonFont.ttf") format("truetype");
}

@font-face {
  font-family: "BeonFont";
  src: local("BeonFont"), url("./assets/fonts/BeonFont.otf") format("opentype");
}

@font-face {
  font-family: "FoxFont";
  src: local("FoxFont"),
    url("./assets/fonts/FoxFont.ttf") format("truetype");
}

@font-face {
  font-family: "PanamaFont";
  src: local("PanamaFont"),
    url("./assets/fonts/PanamaFont.otf") format("opentype");
}