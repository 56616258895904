.LNavbar {
  background-color: white; /*DARK-GREY --> 150, 152, 154*/ /*RED --> 237, 50, 55*/
  text-decoration: none;
}

.logo {
  height: 100px;
  width: 102px;
}

.logo-box {
  margin: 5px !important;
  width: 10%;
}

@property --offset {
  syntax: "<length>";
  inherits: false;
  initial-value: 0;
}

.navlinks-box {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
}

.links {
  /* color: rgb(237, 50, 55); */
  color: #990011;
  margin: 0px 10px;
  padding: 0px 5px;
  font-size: 20px;
  font-family: "El Messiri", sans-serif;
  text-underline-offset: var(--offset, 0.2em);
  /* text-decoration: underline 0.15em; */
  transition: --offset 400ms, text-decoration-color 400ms;
}

.links:hover,
.links:focus,
.links-selected {
  text-decoration: underline;
  color: rgb(150, 152, 154);
  --offset: 0.4em;
  text-decoration-color: red;
}

.navbar-toggler-icon {
  background-image: url("../../assets/nav_toggle.webp") !important;
  width: 51px !important;
  height: 50px !important;
}

.navbar-toggler {
  border-color: white !important;
}

.rotate-nav {
  -webkit-animation-name: rotateToggleBtnAnimation;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-name: rotateToggleBtnAnimation;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@media (min-width: 992px) {
  .basicnavbar1 {
    display: flex;
    justify-content: flex-end;
  }
}

@keyframes rotateToggleBtnAnimation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(90deg);
  }
}

.rotate-nav-opp {
  -webkit-animation-name: rotateToggleBtnAnimationOpp;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-name: rotateToggleBtnAnimationOpp;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes rotateToggleBtnAnimationOpp {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-90deg);
  }
}
