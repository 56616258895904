.product-category-heading,
.product-category-title {
  text-transform: capitalize;
  font-family: "TeslaFont";
  font-size: 75pt;
  color: #990011ff;
  font-weight: 500;
}

.product-category-mcb-heading {
  text-transform: uppercase;
  font-family: "TeslaFont";
  font-size: 75pt;
  color: #990011ff;
  font-weight: 500;
}

.product-doric-animated {
  /* background-image: url(/css/images/logo.png); */
  background-repeat: no-repeat;
  /* background-position: left top; */
  /* padding-top:95px; */
  /* margin-bottom:60px; */
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (max-width: 576px) {
  .product-category-title {
    font-size: 20vw !important;
  }
}

@-webkit-keyframes product-doric-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes product-doric-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.product-doric-fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.product-individual-row {
  /* margin: 0px;
  margin-bottom: 40px;
  padding: 0px; */
  display: flex;
  justify-content: space-between;
}

.productCategory-subcategory-heading {
  /* font-family: "NeonFont"; */
  font-family: "Allerta Stencil", sans-serif;
  font-size: 36pt;
  color: rgb(0, 0, 0);
  font-weight: 800;
  text-align: center;
}
