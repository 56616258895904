.about-container {
  background-image: url("../../assets/page_background/aboutbg.gif");
  background-size: none 100%;
  background-repeat: no-repeat;
}

.about-heading {
  font-family: "TeslaFont";
  font-size: 75pt;
  color: #990011ff;
  font-weight: 500;
}

.about-subheading {
  font-family: "BeonFont";
  font-size: 30pt;
  font-weight: 200;
  color: rgb(129, 109, 109);
  text-align: center;
}

.about-title-subheading {
  font-family: "Raleway";
  font-size: 25pt;
  font-weight: 400;
  color: rgb(0, 0, 0);
  text-align: center;
}
