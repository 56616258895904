.contactbox {
  padding: 15px;
}

.contactbox_row1 {
  text-align: center;
  background-color: antiquewhite;
}

.contactInfoColumn {
  border-radius: 15px;
  background-color: antiquewhite;
  color: black;
  padding: 20px;
}

.contactInfoColumnItems {
  margin: 5px;
  margin-bottom: 20px;
  font-family: "Allerta Stencil", sans-serif;
}

.item-row:hover .item path,
.item-row:hover .item path {
  color: #990011 !important;
}

.contactInfoColumnIcons {
  margin: 5px;
  margin-bottom: 20px;
  align-items: flex-end;
}

.icons {
  display: flex;
  justify-content: space-around;
}

.contactFormRow {
  padding: 5px;
  margin-bottom: 10px !important;
}

.formSubmitButton {
  display: flex;
  justify-content: center;
}

.phoneborder {
  border: 2px solid #3f51b5 !important;
  box-shadow: none !important;
}

.contact-subheading {
  font-family: "Raleway";
  font-size: 25pt;
  font-weight: 400;
  color: rgb(0, 0, 0);
  text-align: center;
}

.glass {
  background: rgba(255, 255, 255, 0.59);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.bgImage {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 15px !important;
  border-radius: 10px;
}

.contactbox_row2 {
  border-radius: 10px;
  padding: 15px;
  margin: 0px !important;
}

.dropdown-bg {
  background: transparent !important;
  border: 1px solid #c4c4c4;
}

.contactPhoneInput {
  height: 56px !important;
  width: inherit !important;
  background-color: transparent !important ;
  border: 1px solid #c4c4c4;
}

.dropdown-bg:hover,
.contactPhoneInput:hover {
  border: 1px solid black;
}

.inner-box-image {
  padding: 5%;
}

.contact-social-media:hover,
.contact-info-icons:hover {
  color: #990011 !important ;
}
/* #faebd7 skin color */
/* #990011 maroon color */

.contact-err-msg {
  color: #990011;
  font-weight: 800;
}

.contact-form-bg {
  background-image: url("./../../assets/page_background/contactbg.svg");
  background-size: cover;
  background-position: center;
  padding: 30px 0px;
}
