.products-subheading {
  font-family: "Raleway";
  font-size: 25pt;
  font-weight: 400;
  color: rgb(0, 0, 0);
  text-align: center;
}

.product-category-row {
  margin: 0 15px !important;
  padding: 0 15px;
  padding-top: 20px !important;
  justify-content: center;
}
