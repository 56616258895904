.products-category-heading {
  font-weight: 1000;
  /* font-family: "Raleway"; */
  /* font-family: "Rye", cursive; */
  font-family: "PanamaFont";
  font-size: 25pt;
  color: #003049;
  text-align: center;
  /* letter-spacing: 0.25cm; */
  /* word-spacing: 0.25cm; */
}

.products-category-card {
  display: flex;
  margin: 10px !important;
  padding: 0 10px;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  height: 35vh;
  justify-content: center !important;
  align-items: center;
}

.products-category-bg-0 {
  background-image: url("../../assets/category_card_bg/T1.svg");
}

.products-category-bg-1 {
  background-image: url("../../assets/category_card_bg/T2.svg");
}

.products-category-bg-2 {
  background-image: url("../../assets/category_card_bg/T3.svg");
}

.products-category-bg-3 {
  background-image: url("../../assets/category_card_bg/T4.svg");
}

.products-category-bg-4 {
  background-image: url("../../assets/category_card_bg/T5.svg");
}

.products-category-bg-5 {
  background-image: url("../../assets/category_card_bg/T6.svg");
}

.products-category-bg-6 {
  background-image: url("../../assets/category_card_bg/W2.svg");
}

.products-category-bg-7 {
  background-image: url("../../assets/category_card_bg/W3.svg");
}

.products-category-bg-8 {
  background-image: url("../../assets/category_card_bg/W4.svg");
}

.products-category-bg-9 {
  background-image: url("../../assets/category_card_bg/W5.svg");
}

.products-category-bg-10 {
  background-image: url("../../assets/category_card_bg/W1.svg");
}
