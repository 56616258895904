/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
} */

.product-wrapper {
  /* font-family: "TimesNewRoman"; */
  font-family: "El Messiri", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.product-card {
  /* background: #990011; */
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(84, 13, 21, 1) 100%
  );
  min-width: 300px;
  min-height: 300px;
  height: 25vw !important;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  position: relative;
}

.product-card .product-img-div {
  min-height: 150px;
  min-width: 120px;
  width: 8vw;
  height: 10vw;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card .product-img-div .product-img {
  min-width: 100px;
  min-height: 130px;
  width: 4vw;
  height: 6vw;
}

.product-card .product-h1-class {
  color: antiquewhite !important;
  /* font-size: 20px; */
  margin: 15px 0 6px 0;
  font-size: inherit;
}

.product-card .product-small-class {
  color: rgba(250, 225, 215, 0.7);
  margin-bottom: 50px;
}

.product-label-class {
  min-width: 300px;
  min-height: 100px;
  /* display: block; */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: -15px;
}

.product-label-class .product-input-class {
  display: none;
}

.product-label-class .product-span-class {
  display: block;
  width: 40px;
  height: 40px;
  background: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}

.product-label-class .product-span-class i {
  /* color: #f1777c; */
  color: rgba(84, 13, 21, 1);
  cursor: pointer;
  transition: all 200ms;
}

.product-label-class .product-dets-class {
  /* background: #f1777c; */
  background-color: #000000;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* display: flex; */
  /* background-image: linear-gradient(326deg, #000000 0%, #ce203c 74%); */
  color: antiquewhite;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  position: absolute;
  min-height: 100px;
  bottom: -1vh;
  border-radius: 12px;
  z-index: -1;
  /* transition: all 250ms cubic-bezier(0, 1.82, 0.58, 1); */
  transition: all 0.8s cubic-bezier(1, 1.82, 0.58, 1);
}
/* 
.product-label-class .product-dets-class ul {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.product-label-class .product-dets-class ul li {
  width: 36px;
  height: 36px;
  background: #fff;
  margin: 0 10px;
  border-radius: 3px;
  cursor: pointer;
}

.product-label-class .product-dets-class ul li a {
  text-decoration: none;
}

.product-label-class .product-dets-class ul li a i {
  display: block;
  text-align: center;
  line-height: 36px;
  color: antiquewhite;
} */

.product-label-class input:checked ~ span i {
  transform: rotate(182deg);
}

.product-label-class input:checked ~ .product-dets-class {
  /* bottom: -110px; */
  bottom: -26vh;
}

@media (max-height: 653px) {
  .product-label-class input:checked ~ .product-dets-class {
    bottom: -34vh;
  }
}

/* new csss */
.container {
  position: relative;
}
/* .container {
  opacity: 0.9;
}
.card:focus {
  opacity: 1 !important;
} */
.container .card {
  position: relative;
  width: 320px;
  height: 450px;
  background: #232323;
  /* border-radius: 20px; */
  overflow: hidden;
  margin: auto;
}

.container .card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #990011;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}

.container .card:focus:before {
  clip-path: circle(300px at 80% -20%);
}

.container .card:after {
  content: "LEVIN";
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 10em;
  font-weight: 800;
  font-style: italic;
  color: rgba(250, 235, 215, 0.05);
}

.container .card .imgBx {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  width: 100%;
  height: 220px;
  transition: 0.5s;
}

.container .card:focus .imgBx {
  top: 0%;
  transform: translateY(0%);
}

.container .card .imgBx img {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-25deg);
  width: 130px;
}

.container .card .contentBx {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 10;
}

.container .card:focus .contentBx {
  height: 210px;
}

.container .card .contentBx h2 {
  position: relative;
  font-size: inherit;
  font-weight: 600;
  letter-spacing: 1px;
  color: antiquewhite;
  margin: 0;
  font-family: "El Messiri", sans-serif;
}
.product-small-className {
  font-weight: 400;
  color: antiquewhite;
  font-family: "El Messiri", sans-serif;
}

.container .card .contentBx .size,
.container .card .contentBx .color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.container .card:focus .contentBx .size {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card:focus .contentBx .color {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
}

.container .card .contentBx .size h3,
.container .card .contentBx .color h3 {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container .card .contentBx .size span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}

.container .card .contentBx .color span {
  width: 20px;
  height: 20px;
  /* background: #8E8E8E; */
  border: antiquewhite solid 3px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.container .card .contentBx a {
  display: inline-block;
  padding: 10px 20px;
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #111;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
  margin-top: 0;
}

.container .card:focus .contentBx a {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
}

.card:focus .border-light:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1000000;
  /* background: linear-gradient(to right, transparent, #1779ff); */
  background: linear-gradient(to right, transparent, #faebd7);
  animation: animate1 2s linear infinity;
  -webkit-animation: 2s linear infinite animate1;
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.card:focus .border-light:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 100%;
  z-index: 1000000;
  /* background: linear-gradient(to bottom, transparent, #1779ff); */
  background: linear-gradient(to bottom, transparent, #faebd7);
  animation: animate2 2s linear infinity;
  -webkit-animation: 2s linear infinite animate2;
  animation-delay: 1s;
  -o-animation-delay: 1s;
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.card:focus .border-light:nth-child(3) {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 3px;
  z-index: 1000000;
  /* background: linear-gradient(to left, transparent, #1779ff); */
  background: linear-gradient(to left, transparent, #faebd7);
  animation: animate3 2s linear infinity;
  -webkit-animation: 2s linear infinite animate3;
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.card:focus .border-light:nth-child(4) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3px;
  height: 100%;
  z-index: 1000000;
  background: linear-gradient(to top, transparent, #1779ff);
  background: linear-gradient(to top, transparent, #faebd7);
  animation: animate4 2s linear infinity;
  -webkit-animation: 2s linear infinite animate4;
  animation-delay: 1s;
  -o-animation-delay: 1s;
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.container .card .contentBx .types h3 {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
  display: inline;
  font-family: "Raleway";
}

.container .card .contentBx .types {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.container .card:focus .contentBx .types {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
  display: inline;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
