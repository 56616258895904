.footer {
  padding: 25px;
  background-color: white;
}

.footer-logo {
  height: 100px;
  width: 102px;
}

.footer-social-media:hover {
  color: #ec1f25 !important;
}

.footer-iframe {
  border-radius: 10px;
  border: 1px solid red;
  box-shadow: 0 0 10px red;
}

.footer-dev-name-link {
  color: black;
  text-decoration: underline;
}

.footer-dev-name-link:hover {
  color: red;
}
