.table th,
.table td {
  padding: 1.55rem !important;
}

.home-heading {
  font-size: 3.5vw;
}
.home-heading-2 {
  /* padding: 0% !important; */
  font-size: 3.5vw;
  text-align: right;
}

.milestones-heading-main {
  font-family: Begumsans-Medium, sans-serif;
  line-height: normal;
  padding: 0% !important;
  font-size: 5rem;
  text-align: center;
}

.milestones-heading {
  line-height: normal;
  padding: 0% !important;
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .home-headings {
    justify-content: center !important;
    align-items: center;
  }
  .home-heading,
  .home-heading-2 {
    font-size: 24pt;
    text-align: center;
  }
}

.home-row {
  background-color: #eca12a;
  padding-top: 5vw;
  padding-bottom: 5vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.home-row-2 {
  background-color: #edc1bb;
  color: white;
  padding-top: 5vw;
  padding-bottom: 5vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.client-count {
  animation: counter 5s ease-in-out;
  counter-increment: num var(--num);
  /* counter-reset: num var(--num); */
  /* font: 800 40px system-ui; */
  font-size: 3rem;
  padding: 2rem;
  --num: 2000;
}
.client-count::before {
  content: counter(num);
}

@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 2000;
  }
}

@property --numb {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.state-count {
  animation: countern 5s ease-in-out;
  counter-increment: numb var(--numb);
  /* counter-reset: num var(--num); */
  /* font: 800 40px system-ui; */
  font-size: 3rem;
  padding: 2rem;
  --numb: 10;
}
.state-count::before {
  content: counter(numb);
}

@keyframes countern {
  from {
    --numb: 0;
  }
  to {
    --numb: 10;
  }
}

@property --years {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.year-count {
  animation: counteryear 5s ease-in-out;
  counter-increment: years var(--years);
  /* counter-reset: num var(--num); */
  /* font: 800 40px system-ui; */
  font-size: 3rem;
  padding: 2rem;
  --years: 20;
}
.year-count::before {
  content: counter(years);
}

@keyframes counteryear {
  from {
    --years: 0;
  }
  to {
    --years: 20;
  }
}

@property --product {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.product-count {
  animation: counterproduct 5s ease-in-out;
  counter-increment: product var(--product);
  /* counter-reset: num var(--num); */
  /* font: 800 40px system-ui; */
  font-size: 3rem;
  padding: 2rem;
  --product: 1000;
}
.product-count::before {
  content: counter(product);
}

@keyframes counterproduct {
  from {
    --product: 0;
  }
  to {
    --product: 1000;
  }
}
